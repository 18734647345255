import 'posthog-js/dist/exception-autocapture'
import 'posthog-js/dist/recorder'
import 'posthog-js/dist/surveys'
import 'posthog-js/dist/tracing-headers'
import 'posthog-js/dist/web-vitals'

import posthog from 'posthog-js/dist/module.no-external'

const posthogData = document.getElementById('posthog-config').dataset

const ph = posthog.init(posthogData.key, {
  api_host: posthogData.host
})

window.posthog = ph
